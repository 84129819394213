import { mapGetters } from 'vuex';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import ApiService from '@/core/services/api.service';
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';
import moment from 'moment';
import WindowRedirectService from '@/core/services/window-redirect.service';

export default {
  props: {
    modal: {
      default: false
    },
    value: Boolean
  },

  name: 'custom-page',
  data() {
    return {
      valid: false,
      isSaving: false,
      shoppingEvent: {
        mainFeedBannerBase64: '',
        beforeEventBannerBase64: '',
        duringEventBannerBase64: '',
        start: null,
        end: null,
        showBeforeStart: null,
        donationGoalId: null,
        cities: null,
        allCities: false,
        itemLimit: null,
        sessionDurationSeconds: null
      },
      donationGoals: [],
      search: '',
      cities: [],
      shoppingEventId: null,
      citiesIsLoading: false,
      goalsAreLoading: false,
      alertMessage: '',
      alert: false,
      requiredRule: [v => !!v || 'Field is required']
    };
  },
  created() {
    this.searchDonationGoals();
    this.searchCities();
    this.shoppingEventId = this.$route.params.shoppingEventId;
    let title =
      this.shoppingEventId != null ? 'Shopping event details' : 'Insert new shopping event';
    this.$store.dispatch(SET_BREADCRUMB, [{ title: title }]);
    if (this.shoppingEventId != null) {
      ApiService.get(`api/shopping-event`, `${this.shoppingEventId}`)
        .then(response => {
          this.$log.debug('Shopping event: ', response.data);
          this.shoppingEvent = response.data;
          if (response.data != null && response.data.donationGoal != null) {
            this.shoppingEvent.donationGoalId = response.data.donationGoal.id;
          }
        })
        .catch(error => {
          this.$log.error('Error: ', error);
          this.errored = true;
        })
        .finally(() => (this.loading = false));
    }
  },
  methods: {
    searchDonationGoals() {
      // Items have already been loaded
      if (this.donationGoals.length > 0) return;

      // Items have already been requested
      if (this.goalsAreLoading) return;

      this.goalsAreLoading = true;
      ApiService.query('api/donation-goal/dropdown-list')
        .then(res => {
          this.donationGoals = res.data;
        })
        .catch(error => {
          this.$log.error('Error: ', error);
          this.errored = true;
        })
        .finally(() => (this.goalsAreLoading = false));
    },
    searchCities() {
      // Items have already been loaded
      if (this.cities.length > 0) return;

      // Items have already been requested
      if (this.citiesIsLoading) return;

      this.citiesIsLoading = true;
      ApiService.query('api/cities')
        .then(res => {
          this.cities = res.data;
        })
        .catch(error => {
          this.$log.error('Error: ', error);
          this.errored = true;
        })
        .finally(() => (this.citiesIsLoading = false));
    },
    removeCityFromList(item) {
      const index = this.shoppingEvent.cities
        .map(function(e) {
          return e.id;
        })
        .indexOf(item.id);
      if (index >= 0) this.shoppingEvent.cities.splice(index, 1);
    },
    previousState() {
      if (WindowRedirectService.isWindowRedirected()) {
        const events = WindowRedirectService.getEvents(window);
        events.triggerClose();
      } else {
        this.$router.go(-1);
      }
    },
    endsWith(suffix, str) {
      return str.indexOf(suffix, str.length - suffix.length) !== -1;
    },
    paddingSize(value) {
      if (this.endsWith('==', value)) {
        return 2;
      }
      if (this.endsWith('=', value)) {
        return 1;
      }
      return 0;
    },
    size(value) {
      return (value.length / 4) * 3 - this.paddingSize(value);
    },
    byteSize(base64String) {
      return this.formatAsBytes(this.size(base64String));
    },
    formatAsBytes(size) {
      return size.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + ' bytes';
    },
    handleImageMainFeed(event) {
      if (event && event.target.files && event.target.files[0]) {
        const file = event.target.files[0];
        // this.toBase64(file);
        this.toBase64(file, base64Data => {
          this.shoppingEvent.mainFeedBannerBase64 = base64Data;
        });
      }
    },
    handleImageBeforeEvent(event) {
      if (event && event.target.files && event.target.files[0]) {
        const file = event.target.files[0];
        // this.toBase64(file);
        this.toBase64(file, base64Data => {
          this.shoppingEvent.beforeEventBannerBase64 = base64Data;
        });
      }
    },
    handleImageDuringEvent(event) {
      if (event && event.target.files && event.target.files[0]) {
        const file = event.target.files[0];
        // this.toBase64(file);
        this.toBase64(file, base64Data => {
          this.shoppingEvent.duringEventBannerBase64 = base64Data;
        });
      }
    },
    toBase64(file, cb) {
      const fileReader = new FileReader();
      fileReader.onload = e => {
        const base64Data = e.target.result;
        cb(base64Data);
      };
      fileReader.readAsDataURL(file);
    },
    validateAndSave() {
      if (this.$refs.form.validate()) {
        this.saveShoppingEvent(this.shoppingEvent);
      }
    },
    saveShoppingEvent(shoppingEvent) {
      this.isSaving = true;
      this.loading = true;
      let cities = [];
      if (
        shoppingEvent.cities != null &&
        shoppingEvent.cities.length > 0 &&
        typeof shoppingEvent.cities[0] === 'number'
      ) {
        shoppingEvent.cities.forEach(function(entry) {
          let city = {};
          city['id'] = entry;
          cities.push(city);
        });
        shoppingEvent.cities = cities;
      }
      if (shoppingEvent.id != null) {
        ApiService.put(`api/shopping-event`, shoppingEvent)
          .then(response => {
            this.$log.debug('Shopping event updated: ' + response);
            this.alert = false;
          })
          .catch(error => {
            this.$log.error('Error: ', error);
            this.alertMessage = error.response.data.message;
            this.alert = true;
            this.errored = true;
          })
          .finally(() => {
            this.loading = false;
            this.isSaving = false;
            console.log('Alert in finally is ' + this.alert);
            if (this.alert === false) {
              this.previousState();
            }
          });
      } else {
        ApiService.post(`api/shopping-event`, shoppingEvent)
          .then(response => {
            this.$log.debug('Shopping event created: ' + response);
            this.alert = false;
          })
          .catch(error => {
            this.$log.error('Error: ', error);
            this.alertMessage = error.response.data.message;
            this.alert = true;
            this.errored = true;
          })
          .finally(() => {
            this.loading = false;
            this.isSaving = false;
            console.log('Alert in finally is ' + this.alert);
            if (this.alert === false) {
              this.previousState();
            }
          });
      }
    }
  },
  computed: {
    ...mapGetters(['currentUserPersonalInfo']),
    show: {
      get() {
        return this.value;
      },
      set(value) {
        if (!value) {
          this.$emit('input', value);
        }
      }
    },
    dateStartFormatted: {
      get() {
        if (this.shoppingEvent.start == null || this.shoppingEvent.start === '') return null;
        return moment
          .utc(this.shoppingEvent.start)
          .local()
          .format('yyyy-MM-DD HH:mm:ss');
      },
      set(value) {
        this.shoppingEvent.start = value;
      }
    },
    dateEndFormatted: {
      get() {
        if (this.shoppingEvent.end == null || this.shoppingEvent.start === '') return null;
        return moment
          .utc(this.shoppingEvent.end)
          .local()
          .format('yyyy-MM-DD HH:mm:ss');
      },
      set(value) {
        this.shoppingEvent.end = value;
      }
    },
    dateShowBeforeStartFormatted: {
      get() {
        if (this.shoppingEvent.showBeforeStart == null || this.shoppingEvent.showBeforeStart === '')
          return null;
        return moment
          .utc(this.shoppingEvent.showBeforeStart)
          .local()
          .format('yyyy-MM-DD HH:mm:ss');
      },
      set(value) {
        this.shoppingEvent.showBeforeStart = value;
      }
    }
  }
};
